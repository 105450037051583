<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-16 23:44:22
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-17 01:19:29
 * @FilePath: /官网迭代/src/views/admin/teacher/sports_info.vue
-->
<template>
  <div class="sports_page">
    <div class="video_block">
      <videoPlayer
        v-if="info.normalUrl"
        :videoid="info.normalUrl"
        :isFree="true"
      />
    </div>
    <div class="video_title">{{ info.name }}</div>
    <div class="video_intro">
      <img id="photo" :src="constant.URL + info.img" class="photo" />
      <div class="btn-start tab" @click="toTest">开始测试</div>
    </div>
    <div class="video_desc">
      <div>{{ info.videoinfo }}</div>
    </div>
  </div>
</template>

<script>
import videoPlayer from "@/components/video.vue";
export default {
  name: "sports",
  components: { videoPlayer },
  data() {
    return {
      option: {},
      info: [],
    };
  },
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.api.course
        .findTestPlanBySportId({ sportId: this.$route.query.id })
        .then((result) => {
          console.log(result);
          this.info = result.data;
        });
    },
    toTest() {
      this.$router.push({
        path: "exerciseTestB",
        query: {
          gradeId: this.$route.query.gradeId,
          isSports: true,
          sportId: this.$route.query.id,
          testlevel: this.$route.query.testlevel,
          gradeType: this.$route.query.gradeType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sports_page {
  width: 100%;
  .video_block {
    width: 100%;
    height: 714px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
  }
  .video_title {
    padding: 0.4rem 0;
    background: #ba3037;
    color: #fff;
    text-align: center;
    font-size: 0.32rem;
    line-height: 0.32rem;
  }
  .video_intro {
    padding: 0.8rem;
    padding-bottom: 0.4rem;
    background: #f7f7f7;
    text-align: center;
  }
  .video_desc {
    padding: 20px 0;
    text-align: center;
    border-top: solid 1px #ddd;
    line-height: 0.4rem;
    font-size: 0.18rem;
    background: #f7f7f7;
    margin-bottom: 1.4rem;
  }
  .photo {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.4rem;
  }
  .btn-start {
    display: block;
    margin: 0px auto;
    width: 3rem;
    height: 0.65rem;
    line-height: 0.65rem;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
    background: #ba3037;
    border-radius: 0.5rem;
  }
  .btn-start:hover {
    color: #fff;
    opacity: 0.8;
  }
}
</style>
